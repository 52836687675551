import React from 'react';
import "./styles/About.css"

const About = () => {
    return (
        <div className="about-page">
            <div className="contact-header">
                <h2>Haqqımızda</h2>
                <p>Ana Səhifə &gt; Haqqımızda</p>
            </div>

            <section className="about-content">
         
            </section>
        </div>
    );
};

export default About;
